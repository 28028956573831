.contact-section {
    /* background: #bdc3c7;*/
    padding: 30px 0 50px 0;
    text-align: center;

    h3 {
        margin: 0;
        padding: 0;
        font-family: 'Open Sans';
        color: rgba(255, 255, 255, .9);
        font-size: 25px;
        margin-bottom: 20px;
        text-transform: uppercase;
    }

    form {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin: 0 auto;
        max-width: 880px;
        height: 450px;
        background: whitesmoke;
        text-align: left;
        padding: 10px 0;

        .subject,
        .content {
            width: 80%;
            margin: 0 auto;

            label {
                display: block;
                padding: 0;
                margin: 0;
                font-family: 'Varela Round';
            }
        }

        .id {
            display: flex;
            justify-content: space-between;
            height: 80px;
            width: 80%;
            margin: 0 auto;

            .name,
            .email,
            {
                display: inline-block;
                width: 45%;

                label {
                    display: block;
                    padding: 0;
                    margin: 0;
                    font-family: 'Varela Round';
                }

                input[name="email"],
                input[name="name"] {
                    width: 100%;
                    height: 40px;
                }
            }
        }

        .subject {
            height: 62px;

            input[name="subject"] {
                width: 100%;
                height: 40px;
            }

            select[name="subjectselect"] {
                width: 100%;
                height: 40px;
            }
        }

        .content {
            textarea {
                width: 100%;
                resize: none;
            }
        }

        button {
            align-text: center;
            justify-content: center;
            margin: 0 auto;
            width: 100px;
            background: #D6AF36;
            padding: 10px;
            font-family: 'Varela Round';
            transition: all .3;
            border: 1px solid #ddd;
            -moz-border-radius: 6px;
            -webkit-border-radius: 6px;
            -o-border-radius: 6px;
            -ms-border-radius: 6px;
            border-radius: 6px;
            -moz-box-shadow: 0 3px 5px #ccc;
            -webkit-box-shadow: 0 3px 5px #ccc;
            -o-box-shadow: 0 3px 5px #ccc;
            -ms-box-shadow: 0 3px 5px #ccc;
            box-shadow: 0 3px 5px #ccc;

            &:hover {
                background: none;
                border: 1px solid rgba(0, 0, 0, .7);
            }
        }
    }
}

