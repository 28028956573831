@use '../../assets/style/base/variables' as *;

.offering-header {
    text-align: center;
    color: $color-hero-is-dark;
    font-size: 1.8em;
    letter-spacing: 0.01em;

    @include renderTabletDevice {
        font-size: 1.35em;
    }

    @include renderMobileDevice {
        font-size: 1.125em;
    }
}

.offering-item {
    border: 1px solid #dbdbdb;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.14);
    margin: 10px 15px;    
    transition: all 0.4s ease-in-out 0s;
}

.offering-item:hover {
    border: 2px solid #e46367;
    margin-top: 10px;
}


.sol-navbar {
    width: 100%;
    position: fixed;
    z-index: 30;
    height: $submenu-height;
    background-color: $theme-primary-body-bg-color;
    padding-left: 11rem;
    padding-right: 11rem;
    box-shadow: 0 4px 6px 0 rgb(0 0 0 / 5%);

    @include reduceNavBarPadding {
        padding-left: 7rem;
        padding-right: 7rem;
    }

    @include removeNavBarPadding {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    @include renderMobileDevice {
        padding-right: 0rem;
    }

    .sol-navbar-wrapper {
        width: 100%;
        margin: auto;
        display: flex;
        height: 100%;

        .sol-brand-wrapper {
            display: flex;
            height: 100%;
            align-items: center;
            width: 46%;
            margin: auto;

            @include renderMobileNavView {
                margin-right: 0.25rem;
            }
        }

        .sol-navbar-routes {
            line-height: 0.9;
            font-size: 0.9rem;
            height: 100%;
            display: flex;
            justify-content: flex-end;
            margin: auto;
            width: 54%;
            align-items: center;

            > .seperator {
                width: 1.5px;
                margin: 1rem 1.25rem;
                align-self: stretch;
                box-sizing: border-box;
                background-color: rgba(255, 255, 255, 0.13);
            }

            > .sol-navbar-item {
                user-select: none;
                color: $color-nav-bar;
                letter-spacing: 0.01em;
                background-color: transparent;
                border-top: 2px solid transparent;
                border-bottom: 2px solid transparent;
                transition: color 0.2s ease-out, border-bottom-color 0.2s ease-out;
                display: flex;
                overflow-x: auto;
                overflow-y: hidden;
                align-items: center;
                height: 100%;
                padding: 0.5rem 0.75rem;
                position: relative;
                flex-grow: 0;
                flex-shrink: 0;

                &:hover {
                    color: $color-blue-highlight;
                }

                &.is-active {
                    color: $color-blue-highlight !important;
                    border-bottom-color: $color-blue-highlight !important;
                }

                &:nth-child(2) {
                    margin-left: 1.25rem;
                }

                svg {
                    font-size: 0.75em;
                    margin: .063rem 0 0 .55rem;
                    color: rgba(255,255,255,.5);
                    transition: color 0.2s ease-out;
                }

                @include renderMobileNavView {
                    font-size: 0.95rem;
                    border-top-width: 4px;
                    padding: 0.75rem 0.2rem 0.75rem 0.2rem;

                    &:nth-child(2) {
                        margin-left: 0.5rem;
                    }

                    &.is-active {
                        border-bottom-color: $color-blue-highlight !important;
                    }
                }
            }

            > .navbar-theme-toggle {
                display: flex;
                line-height: 1.25;
                margin: 0 0 0 1.25rem;
            }
        }
    }
}