﻿@use '../base/variables' as *;

.hero.is-dark {
  background-color: $color-hero-is-dark;

  .hero-body {
    padding: 1.5rem 1.5rem 2.5rem;

    svg {
      color: $color-blue-highlight;
    }

    hr {
      background-color: rgba(255,255,255,0.13);
      height: 1.5px;
      margin: 0 auto 1.55rem auto;
      width: 65%;
    }

    .feature {
      font-size: 1.05em;
      line-height: 1.25;

      &:not(:last-child) {
        margin-bottom: 0.65rem;
      }

      svg {
        font-size: 90%;
        margin-right: 5px;
      }

      @include centerStackedColumnContent {
        line-height: 1.6;
      }
    }
  }

  .title {
    color: #fff;
    font-size: 2.8em;
    margin: auto auto 1.25rem;
    font-family: Segoe Script;

    @include renderMobileDevice {
      font-size: 1.8em;
    }
  }
}

.sendbtn {
    color: #d4af37;
    margin: 5px;
    padding: 5px;
    border-style: solid;
    border-color: antiquewhite;
    background-color: beige;
    border: 1px solid #ddd;
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
    -o-border-radius: 6px;
    -ms-border-radius: 6px;
    border-radius: 6px;
    -moz-box-shadow: 0 3px 5px #ccc;
    -webkit-box-shadow: 0 3px 5px #ccc;
    -o-box-shadow: 0 3px 5px #ccc;
    -ms-box-shadow: 0 3px 5px #ccc;
    box-shadow: 0 3px 5px #ccc;
}

.dashboard-content {
  padding: 1.5rem 0 2rem;
  transition: color 0.2s ease-out;

  code {
    transition: color 0.2s ease-out, background-color 0.2s ease-out;
    background-color: #ebedf0;
    color: #242424;
  }

  hr {
    transition: background-color 0.2s ease-out;
    background-color: hsla(0,100%,0%,.13);
    margin: 0.75rem 0;
    height: 1.5px;
  }

  > .columns {
    > .column {
      padding: 1.85rem 1.25rem !important;

      > .title {
        color: #6d6d6d;
        text-align: center;
        font-size: 1.825rem;
        transition: color 0.2s ease-out;
      }

      .content div:not(:last-child) {
        margin-bottom: 1em;
      }
    }

    @media all and (min-width: 769px) {
      > hr {
        display: none;
      }
    }
  }
}
