@use '../base/variables' as *;

.footer {
    position: fixed;
    bottom: 0;
    color: #fff;
    background-color: $color-nav-bar;
    padding: 1rem 1.5rem;
    font-size: 1.15rem;
    width: 100%;
    margin: auto;

    @include centerStackedColumnContent {
        font-size: 1rem;
    }

    > .buttons {
        margin-bottom: 0;

        > .button {
            margin-bottom: 0;
            margin-right: 0 !important;
            color: #fff;
            font-size: 1.4rem;
            background-color: transparent;
            border-color: transparent;
            padding: 0 .5em;
            transition: color 0.2s ease-out;

            &:hover {
                color: $color-blue-highlight;
            }

            &:first-child {
                margin-left: auto !important;
            }

            &:last-child {
                margin-right: auto !important;
            }

            .icon {
                align-items: baseline;
            }
        }
    }
}