﻿@use 'variables' as *;

body {
    background-color: $theme-primary-body-bg-color;
    transition: background-color 0.2s ease-out;

    .rn-button {
        font-weight: 600;
        font-size: 20px;
        padding: 4px;
        border: 2px solid #DDBF5F;
        border-radius: 25px;
        margin: 15px 15px 5px 15px;
        color: #FB8F3D;
        background: white;
        border-color: #FB8F3D;
        text-align: center;
    }


    .min-df {
        color: #00B0F0;
    }

    .hyb-df {
        color: #90C226;
    }

    .full-df {
        color: #FFC000;
    }

    .common-df {
        color: #7030A0;
    }

    .li-theme1 {
        ul {
            display: table-row;
            list-style: none;
        }

        li {
            display: table-row;
            color: black;
        }

        li.li-pro::before {
            display: table-cell;
            text-align: right;
            content: "+";
            font-size: 1em;
            font-weight: bold;
            color: forestgreen !important;
            padding-right: 12px;
        }

        li.li-pro {
            text-align: left;
            font-size: 1em;
            font-weight: bold;
            color: forestgreen !important;
            padding-right: 2px;
        }

        li.li-con::before {
            display: table-cell;
            text-align: right;
            content: "-";
            font-size: 1em;
            font-weight: bold;
            color: red !important;
            padding-right: 12px;
        }

        li.li-con {
            text-align: left;
            font-size: 1em;
            font-weight: bold;
            color: red !important;
            padding-right: 2px;
        }

        li::before {
            display: table-cell;
            text-align: right;
            content: "•";
            font-size: 1em;
            font-weight: bold;
            color: black;
            padding-right: 12px;
        }
    }


    div svg {
        display: inline-block;
        vertical-align: middle;
    }

    .container {
        background-color: white;
        margin: 20px;
    }

    .subcontainer {
        background-color: white;
        margin-left: 50px;
        margin-right: 50px;
        margin-bottom: 90px;
    }

    &.secondary-theme {
        background-color: $theme-secondary-body-bg-color;

        .dashboard-content {
            color: $theme-primary-body-bg-color;

            .title {
                color: $theme-primary-body-bg-color !important;
            }

            code {
                background-color: $theme-secondary-code-bg-color;
                color: #70E9FF;
            }

            hr {
                background-color: rgba(255, 255, 255, 0.15);
            }
        }
    }
}

    svg {
        max-width: 100%;
    }

    .title {
        width: fit-content;
        padding-right: 20px;
        padding-left: 15px;
        color: #D6AF36;
        background: white;
        border-radius: 25px;
        border-color: #D6AF36;
        box-shadow: 0 4px 6px 0 rgb(0 0 0 / 5%);
        font-weight: 700;

        @include renderTabletDevice {
            font-size: 1.75em;
        }

        @include renderMobileDevice {
            font-size: 1.5em;
        }
    }

    code {
        background-color: $theme-secondary-code-bg-color;
        color: #70E9FF;
        padding: 0.25rem 0.5rem;
        border-radius: 3px;
        font-size: 87.5%;
    }

    .view-wrapper {
        min-height: 875px;
        padding-top: $navbar-height;
    }

    .is-132x132 {
        width: 132px;
        height: 132px;
    }

    .subtitle {
        color: $color-hero-is-dark;
        width: fit-content;
        padding-right: 20px;
        padding-left: 15px;
        border-radius: 25px;
        border-color: #FB8F3D;
        box-shadow: 0 4px 6px 0 rgb(0 0 0 / 5%);
        font-size: 1.6em;
        letter-spacing: 0.01em;

        @include renderTabletDevice {
            font-size: 1.35em;
        }

        @include renderMobileDevice {
            font-size: 1.125em;
        }
    }

    .is-horizontal-center {
        align-items: center;
        justify-content: center;
    }